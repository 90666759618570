@import url('https://fonts.googleapis.com/css?family=Nunito');

html,
body {
    font-family: Nunito;
    background-color: #fcfcfc !important;
    height: 100%;
}

select {
    -webkit-appearance: none;
    appearance: none;

    /* Add paddings to accommodate arrow */
    padding: 8px;
    padding-right: 30px;

    /* Add arrow icon */
    /* Source: https://material.io/tools/icons/?icon=keyboard_arrow_down&style=baseline */
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" xml:space="preserve"><path fill="grey" d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"/><path fill="none" d="M0,0h24v24H0V0z"/></svg>');
    background-position: center right;
    background-repeat: no-repeat;
}

.navbar-brand-img {
    height: 60px;
}

.navbar-brand-img-big {
    height: 80px;
}

@media all and (max-width: 764px) {
    .navbar-brand-img {
        height: 40px;
    }

    .navbar-brand-img-big {
        height: 60px;
    }
}


.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: 0.7;
    z-index: 100;
    cursor: "pointer";
}

.over-overlay {
    position: absolute;
    left: 5%;
    width: 90%;
    z-index: 200;
}

.btn-outline-default:hover {
    background-color: #e2025f !important;
    border-color: #e2025f !important;
}

.search-in-zone-container {
    position: absolute;
    left: 0;
    right: 0;
    height: 15%;
    background: linear-gradient(0deg, rgba(105, 105, 105, 0) 0%, rgba(105, 105, 105, 0.5) 100%);
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.chat-button {
    padding: 5px 10px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, .2);
    border-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
}

button.chat-button:disabled {
    background-color: rgba(255, 255, 255, .2);
    border-color: rgba(255, 255, 255, 1);
}

button.chat-button:hover {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
    color: #e2025f;
}

.index-chat-bot-row .rounded {
    border-radius: 20px !important;
    padding: 10px 12px !important;
    font-size: 15px;
    line-height: 1.1;
}

.index-chat-bot-row .rounded.text-white {
    max-width: 85vw;
    text-align: right;
    padding-right: 15px !important;
}

.index-chat-bot-row input {
    padding: 5px 10px;
    height: calc(1.5em + .75rem);
    margin-bottom: 5px;
    border-radius: 8px;
}

.index-chat-bot {
    mask-image: url("../img/mask-chat.png");
}

.centered_overlay {
    position: absolute;
    width: 90%;
    max-width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    background: #ffffff;
    z-index: 1000;
}

.schedule {
    line-height: 5px;
    line-height: 5px;
    height: 5px;
    border-spacing: 1;
    border-collapse: separate;
    table-layout: fixed;
}

.schedule-box {
    border-radius: 4px;
    margin: 1px;
}

table.schedule tbody tr th {
    vertical-align: top;
}

table.schedule tbody:before {
    content: '';
    display: block;
    height: 10px;
}

.c0 {
    background-color: #f5f5f5;
}

.h-night .c0,
.h-night .hour {
    opacity: .5;
}

.c1 {
    background-color: #60c7bc;
}

.c2 {
    background-color: #f5f5f5;
}

.c3 {
    background-color: #c1eae6;
}

.profile-field,
.profile-field>input {
    font-size: 1rem !important;
    padding-top: .3rem !important;
    padding-bottom: .3rem !important;
    line-height: 1 !important;
    height: auto !important;
}

.rtl-calendar>.rdtPicker {
    right: 0;
}

.toast-header>button.close>span:not(.sr-only) {
    color: white;
}

@keyframes transition {
    0% {
        margin-left: -100%;
    }

    100% {
        margin-left: 0;
    }
}

#informationPage {
    animation: transition .5s ease-in-out 0s forwards;
}

.index-chat-bot {
    line-height: 1;
}

.bg-banner {
    background: url("../img/background-homepage.jpg") no-repeat center center fixed;
    background-size: cover;
}

.bg-alternative {
    background: #620152 !important;
}

.bg-alternative-2 {
    background: #ff2d5e !important;
}

@media all and (max-width:809px) {
    .stat-col-1 {
        width: 100%;
    }

    .stat-col-2 {
        width: 100%;
    }

    .stat-col-3 {
        width: 100%;
    }
}

@media all and (min-width:810px) and (max-width:1619px) {
    .stat-col-1 {
        width: calc(50% - 30px);
        margin-right: 30px;
        float: left;
    }

    .stat-col-2 {
        width: 50%;
        float: left;
    }

    .stat-col-3 {
        width: 100%;
        float: left;
    }
}

@media all and (min-width:1620px) {
    .stat-col-1 {
        width: 370px;
        margin-right: 30px;
        float: left;
    }

    .stat-col-2 {
        width: 420px;
        margin-right: auto;
        float: left;
    }

    .stat-col-3 {
        width: 620px;
        margin-left: 30px;
        float: left;
    }
}

.mainlink-dashboard {
    display: block;
    border: 2px solid #fff;
    color: #fff !important;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    padding: 20px 20px !important;
    font-family: nunito;
    text-decoration: none;
    font-size: 1.3em !important;
    max-width: 350px;
}

.mainlink-dashboard,
.mainlink-dashboard * {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.mainlink-dashboard:hover {
    background-color: rgba(0, 0, 0, .2);
}

.mainlink-dashboard .mainlink-dashboard-img {
    display: block;
    float: left;
    width: calc(20% - 20px);
    margin-right: 20px;
}

.mainlink-dashboard .mainlink-dashboard-title {
    display: block;
    float: left;
    width: calc(60% - 20px);
    line-height: 1.2 !important;
    min-width: 150px;
    max-width: 160px;
    margin-top: -5px;
}

.mainlink-dashboard:hover .mainlink-dashboard-title {
    padding-left: 10px !important;
}

.mainlink-dashboard .mainlink-dashboard-icon {
    display: block;
    line-height: 55px !important;
    height: 0px;
    float: right;
    width: calc(20%);
    color: #fff;
    text-align: right;
}

.mainlink-dashboard:hover .mainlink-dashboard-icon {
    padding-right: 10px !important;
}

.mainlink-dashboard .mainlink-dashboard-icon i {
    font-size: 1.7em !important;
}

.mainlink-dashboard:after {
    content: ' ';
    display: block;
    clear: both;
    visibility: hidden;
}

.legal-text {
    max-width: 1000px;
    margin: 50px auto;
    padding-bottom: 50px;
}

.legal-text p {
    line-height: 1.1 !important;
}