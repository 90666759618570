.chat-container {
  height: 76vh;
  margin-top: 40px;
}

.chat-title {
  width: 66.6666%;
}

@media all and (max-width: 764px) {
  .chat-title {
    width: 100%;
  }
  .chat-container {
    height: 72vh;
  }
}

.imessage {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  font-family: "SanFrancisco";
  font-size: 14px;
  margin: 0 !important;
  padding: 0.5rem 1.5rem;
  height: 100%;
  overflow-y: scroll;
  list-style-type: none;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.imessage li {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: .5rem .875rem;
  position: relative;
  word-wrap: break-word;
  font-size: 14px;
  text-align: left;
}

.imessage li>*,
.imessage li>*>* {
  font-size: 14px;
  line-height: 1.25;
}

li.from-me {
  align-self: flex-end;
}

ul.imessage>li {
  z-index: 10;
}

.overlay-blocker {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
  z-index: 300;
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #e2025f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d63779;
}

::-webkit-scrollbar-track {
  background: rgba(255,255,255,.1);
  border-radius: 10px;
}

ul.imessage .right-arrow-chat {
  position: absolute;
  width: 0;
  height: 0;
  right: -15px;
  bottom: 0px;
  border: 15px solid;
  z-index: -1;
}

ul.imessage .right-arrow-chat-after {
  position: absolute;
  background-color: #fff;
  width: 15px;
  height: 30px;
  border-bottom-left-radius: 50%;
  right: -15px;
  bottom: 0px;
}

ul.imessage .left-arrow-chat {
  position: absolute;
  width: 0;
  height: 0;
  left: -15px;
  bottom: 0px;
  border: 15px solid;
  z-index: -1;
}

ul.imessage .left-arrow-chat-after {
  position: absolute;
  background-color: #fff;
  width: 15px;
  height: 30px;
  border-bottom-right-radius: 50%;
  left: -15px;
  bottom: 0px;
}


/* transform does weird things while changing the size of the screen:
  bigger screen makes it so the bubble has 1px under the tail */

.right-arrow-chat::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -45px;
  transform: translate(-30px, -2px);
  width: 15px;
  height: 30px;
}

li[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

li.from-me~li.from-me {
  margin: 0.25rem 0 0;
}

li.from-me~li.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

li.from-me~li.from-me:last-child {
  margin-bottom: 0.5rem;
}



/*MY CODE*/

/*END MY CODE*/

li[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

li[class^="from-"].emoji::before {
  content: none;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}

/* general styling */

@font-face {
  font-family: "SanFrancisco";
  src: url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2") format("woff2"), url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff") format("woff");
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  margin: 0;
}

.container {
  margin: 0 auto;
  max-width: 600px;
  padding: 1rem;
}

h1 {
  font-weight: normal;
  margin-bottom: 0.5rem;
}

h2 {
  border-bottom: 1px solid #e5e5ea;
  color: #666;
  font-weight: normal;
  margin-top: 0;
  padding-bottom: 1.5rem;
}

.comment {
  color: #222;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  max-width: 100%;
  padding: 0;
}

@media screen and (max-width: 800px) {
  body {
    margin: 0 0.5rem;
  }

  .container {
    padding: 0.5rem;
  }

  .imessage {
    font-size: 14px;
    margin: 0 auto 1rem;
    padding: 0.25rem 0.875rem;
  }

  .imessage p {
    margin: 0.5rem 0;
  }
}

/* Menu button */

.dot-menu {
  position: relative;
}

/* .dot-menu:hover .dot-menu__label {
    background-color: rgba(0, 0, 0, 0.1);
  } */

.dot-menu__checkbox {
  display: none;
}

.dot-menu__label:before,
.dot-menu__label:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #3d3d23;
  transition: 0.8s;
  left: 25px;
  transition: 0.2s;
}

.dot-menu__label:after {
  top: 40px;
}

.dot-menu__label:before {
  top: 10px;
}

.dot-menu__checkbox:checked~.dot-menu__label {
  background-color: rgba(0, 0, 0, 0.1);
}

.dot-menu__checkbox:checked~.dot-menu__label:before,
.dot-menu__checkbox:checked~.dot-menu__label span:before,
.dot-menu__checkbox:checked~.dot-menu__label:after {
  background-color: #000000;
  top: 25px;
}

.dot-menu__checkbox:checked~.dot-menu__label span:before {
  width: 30px;
  left: 15px;
}

.dot-menu__label {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.03);
  display: block;
  text-indent: -9999em;
}

.dot-menu__label span:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #3d3d23;
  transition: 0.8s;
  left: 25px;
  top: 25px;
}

.line-vertical {
  border-right: 1px solid #333;
  height: 550px
}


.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike>span {
  position: relative;
  display: inline-block;
}

.strike>span:before,
.strike>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background-color: #E1EAF0;
}

.strike.strike-primary>span:before,
.strike.strike-primary>span:after {
  background: #e2025f;
}

.d-contents {
  display: contents;
}

.strike>span:before {
  right: 100%;
  margin-right: 15px;
}

.strike>span:after {
  left: 100%;
  margin-left: 15px;
}

b,
strong {
  font-weight: bold !important;
}

.chat-preview,
.chat-preview>*,
.chat-preview>*>*,
.chat-preview>*>*>* {
  min-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}

.ck .ck-toolbar {
  border: none !important;
}

.ck .ck-content {
  border-radius: 10px !important;
  max-height: 100px !important;
}

.ck .ck-content>p {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 14px;
  line-height: 1.25;
}